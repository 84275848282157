(function($) {

    if (typeof $ === 'undefined') {
        return;
    }

})(jQuery);

var elvington = {};

elvington.navToggle = function() {

    var nav = $('.nav');
    var list = $('.nav > ul');

    var control = $('<div class="nav-toggle"></div>');
    var link = $('<a href="#" class="nav-toggle-link">Show Navigation</a>');

    list.addClass('nav-off');

    link.click(function(event) {

        if(list.hasClass('nav-off')) {
            list.hide();
            list.slideDown();
            list.removeClass('nav-off');
            link.text('Hide Navigation');
            link.addClass('nav-toggle-link-on');

        } else {
            list.slideUp(400, function() {
                list.removeAttr('style');
                list.addClass('nav-off');
                link.text('Show Navigation');
                link.removeClass('nav-toggle-link-on');
            });
        }

        link.blur();
        event.preventDefault();

    });

    control.append(link);
    nav.prepend(control);

};

$('#map').mapify({
    points: [
        {
            lat: 53.9562104,
            lng: -1.0682633,
            InfoWindow: '<strong>Elvington Floorcraft,</strong><br> Charlotte House,<br>6a James Street,<br> York,<br> YO10 3WW',
            title: 'Elvington Floorcraft',
            marker: '/static/images/marker.png',
        }
    ],

    key: 'AIzaSyCgF-NCLRDA-NzwHOddUuGrrpRNkc00HCM',
    zoom: 15,
});

$(document).ready(function(){

    elvington.navToggle();

    $( '.homepage-banner' ).cycle({
        speed: 600,
        captionPlugin: "caption2",
    });

    if ($.fn.magnificPopup !== undefined) {
        $('.gallery').magnificPopup({
            type: 'image',
            delegate: '> a',
            closeOnContentClick: true,
            closeBtnInside: false,
            fixedContentPos: true,
            image: {
                verticalFit: true
            },
            zoom: {
                enabled: true,
                duration: 300 // don't foget to change the duration also in CSS
            },
            gallery: {
                enabled: true,
                navigateByImgClick: true,
            },
        });

        $('.popup').magnificPopup({
            type: 'image',
            closeOnContentClick: true,
            image: {
                verticalFit: true
            },
        });
    };

    /* SLIDESHOW INITIATION */
    if ($.fn.cycle !== undefined) {

        var slideshows = $('.cycle-slideshow').on('cycle-next cycle-prev', function(e, opts) {
            // advance the other slideshow
            slideshows.not(this).cycle('goto', opts.currSlide);
        });

        $('#cycle-2 .cycle-slide').click(function(){
            var index = $('#cycle-2').data('cycle.API').getSlideIndex(this);
            slideshows.cycle('goto', index);
        });

    };

});
